import BaseAPIClient from '@/services/baseClient';
import apiClient from './apiClient';
import {USER_ROLE_KEY, USER_TOKEN_KEY} from '@/helpers/localStorageLabels';
export class OngoingAPIClient extends BaseAPIClient {
  createInstance() {
    super.createInstance();
    this.instance.defaults.baseURL = process.env.NODE_ENV === 'production'
      ? '/api/v1/ongoing/'
      : process.env.VUE_APP_ONGOING_API_URI;
    
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          const userToken = sessionStorage.getItem(USER_TOKEN_KEY);
          const userRole = localStorage.getItem(USER_ROLE_KEY);
          if (userRole === 'ANALYST') {
            //TEMP
            const localStorageData = {};
            for (let i = 0; i < localStorage.length; i++) {
              const key = localStorage.key(i);
              const value = localStorage.getItem(key);
              localStorageData[key] = value;
            }
            (async function () {
              await apiClient.cleanLocalStorage('ongoingClient.js', JSON.stringify(localStorageData));
            })();
            //TEMP
            apiClient.logout(userToken);
            localStorage.clear();
            location.reload();
          }
        }
        return Promise.reject(error);
      },
    );
  }

  async delete(ids) {
    return this.instance.delete('query-log', { data: { ids } });
  }

  async reset(ids) {
    return this.instance.post('query-log/reset/bulk', { ids });
  }

  async refresh(ids) {
    return this.instance.post('query-log/refresh/bulk', { ids });
  }

  async updateStatus(id, status) {
    return this.instance.put(`/query-log/${id}`, { status });
  }

  async bulkUpdateStatus(ids, status) {
    return this.instance.put('/query-log/', { ids, payload: { status } });
  }

  async getCustomers() {
    return this.instance.get('/customers');
  }

  async getCustomer({ externalId }) {
    return this.instance.get(`/customers/${externalId}`);
  }

  async newCustomer(customer) {
    return this.instance.post('/customers', customer);
  }

  async updateCustomer(update) {
    const { externalId } = update;
    return this.instance.put(`/customers/${externalId}`, update);
  }

  async resetCustomer({ externalId }) {
    return this.instance.post(`/customers/${externalId}/reset`);
  }

  async refreshCustomer({ externalId }) {
    return this.instance.post(`/customers/${externalId}/refresh`);
  }

  async deleteCustomer({ externalId }) {
    return this.instance.delete(`/customers/${externalId}`);
  }

  async getPipelines() {
    return this.instance.get('/pipeline/pipelines');
  }

  async runPipeline(ids) {
    const [sourceJobId, targetJobId] = ids;
    return this.instance.post('/pipeline/pipelines', { sourceJobId, targetJobId });
  }

  async getDeltas() {
    return this.instance.get('/delta/deltas');
  }

  async getJobs() {
    return this.instance.get('/delta/deltas/jobs');
  }
}

export default new OngoingAPIClient();
