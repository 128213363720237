<template>
    <v-navigation-drawer
        v-model='drawerOpen'
        class='elevation-1'
        left> 
          <v-list nav>
            <template v-for='item in filteredItems' >
              <!-- Single items -->
              <v-list-item
                v-if='item.type === "single"'
                :key='item.name'
                :prepend-icon='item.icon'
                :value='item.path'
                @click='routeTo(item.path)'
              >
                <div>
                  {{ item.name }}
                </div>
              </v-list-item>

              <!-- Multi items with children -->
              <v-list-group
                v-else-if='item.type === "multi"'
                :key='item.icon'
                :prepend-icon='item.icon'
              >
                <template v-slot:activator='{ props }'>
                  <v-list-item
                    v-bind='props'
                  >
                    <div>
                      {{ item.name }}
                    </div>
                  </v-list-item>
                </template>

                <v-list-item
                  v-for='child in item.children'
                  :key='child.name'
                  :prepend-icon='child.icon'
                  :value='child.path'
                  @click='routeTo(child.path)'
                  style='padding-left: 30px !important'
                >
                  <div>
                    {{ child.name }}
                  </div>
                </v-list-item>
              </v-list-group>
            </template>
          </v-list>
        <template v-slot:append>
            <div class='pa-2'>
                <v-btn block color='primary' @click='doLogout'>
                Logout
                </v-btn>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import ActionRoles from '@/helpers/actionRoles';
import { SOLUTIONS } from '@/helpers/dataLabels';
import {COMPARE_NAMES_KEY, USE_BATCH} from '@/helpers/localStorageLabels';
import { rolesObject } from '@/helpers/roles';

export default {
    props: {
        isDrawerOpen: {
            type: Boolean,
        },
    },
    data() {
        return {
            drawerOpen: false,
            userSolutions: localStorage.getItem('solutions'),
            items: [
                {
                    name: 'Home Page',
                    icon: 'mdi-home',
                    path: '/',
                    type: 'single',
                    show: true,
                },
                {
                    name: 'Manual Search',
                    icon: 'mdi-format-list-bulleted',
                    path: '/manual-search',
                    type: 'single',
                    show: function() {return this.isSolutionAml && !this.isUserBsa;} ,
                },
                {
                    name: 'Compare Names',
                    icon: 'mdi-compare-horizontal',
                    path: '/compare-names',
                    type: 'single',
                    show: function() {return this.useCompareNames;},
                },   
                {
                    name: 'Audit Trail',
                    icon: 'mdi-format-list-bulleted',
                    type: 'multi',
                    show: function() { return this.isUserSystemAdmin || this.isUserAdmin || this.isUserChief || this.isUserBsa;},
                    children: [
                        {
                            name: 'Wire',
                            icon: 'mdi-format-list-bulleted',
                            path: '/query-log',
                            type: 'single',
                            show: function() { return this.isSolutionWire;},
                        },
                        {
                            name: 'Manual Search',
                            icon: 'mdi-format-list-bulleted',
                            path: '/aml/query-log',
                            type: 'single',
                            show: function() { return this.isSolutionAml;},                    
                        },
                        {
                          name: 'Trade Finance 360',
                          icon: 'mdi-format-list-bulleted',
                          path: '/tf/360-log',
                          type: 'single',
                          show: function() { return this.isSolutionTf360;},    
                        },
                        {
                          name: 'Trade Finance 799',
                          icon: 'mdi-format-list-bulleted',
                          path: '/tf/799-log',
                          type: 'single',
                          show: function() { return this.isSolutionTf799;},    
                        },
                        {
                          name: 'Trade Finance Searches',
                          icon: 'mdi-format-list-bulleted',
                          path: '/tf/manual-log',
                          type: 'single',
                          show: function() { return this.isSolutionTf360 || this.isSolutionTf799;},    
                        },
                    ],
                },  
                {
                    name: 'Upload ACH File',
                    icon: 'mdi-file-upload-outline',
                    path: '/ach-upload',
                    type: 'single',
                    show: function() {return ActionRoles.actionRoles.includes(this.userRole) && this.isSolutionAch;},
                },
                {
                    name: 'Trade Finance Search',
                    icon: 'mdi-magnify',
                    path: '/tf-search',
                    type: 'single',
                    show: function() {return (this.isSolutionTf360 || this.isSolutionTf799) && !this.isUserBsa;},
                },
                {
                    name: 'Batches',
                    icon: 'mdi-file-compare',
                    type: 'multi',
                    show: function() {return this.useBatch && !this.isUserBsa;},
                    children: [
                        {
                            name: 'Create Batch',
                            icon: 'mdi-file-document-arrow-right-outline',
                            path: '/batches/new',
                            type: 'single',
                            show: true,
                        },
                        {
                            name: 'Batches List',
                            icon: 'mdi-file-multiple',
                            path: '/batches',
                            type: 'single',
                            show: true,
                        },
                    ],
                },
                {
                    name: 'PKYC files',
                    icon: 'mdi-file-upload-outline',
                    path: '/pkyc-upload',
                    type: 'single',
                    show: function() {return ActionRoles.adminRoles.includes(this.userRole) && this.isSolutionPkyc;},              
                },
                {
                    name: 'Upload Trade Finance 799 File',
                    icon: 'mdi-file-upload-outline',
                    path: '/tf799-upload',
                    type: 'single',
                    show: function() {return this.isSolutionTf799 && !this.isUserBsa;},
                },
                {
                    name: 'Upload Trade Finance 360 File',
                    icon: 'mdi-file-upload-outline',
                    path: '/tf360-upload',
                    type: 'single',
                    show: function() {return this.isSolutionTf360 && !this.isUserBsa;},  
                },
                {
                  name: 'Case Mgmt.',
                  icon: 'mdi-file-document',
                  type: 'multi',
                  show: function() {return ActionRoles.actionRoles.includes(this.userRole);},
                  children: [
                      {
                          name: 'Wire Queries',
                          icon: 'mdi-table-of-contents',
                          path: '/supervision/wire/tables',
                          show: function() {return this.isSolutionWire;},
                          type: 'single',
                      },
                      {
                          name: 'Wire Dashboard',
                          icon: 'mdi-view-dashboard',
                          path: '/supervision/wire/dashboard',
                          show: function() {return this.isSolutionWire;},
                          type: 'single',
                      },
                      {
                          name: 'Trade Finance 360 Queries',
                          icon: 'mdi-table-of-contents',
                          path: '/supervision/tf360/tables',
                          show: function() {return this.isSolutionTf360;},
                          type: 'single',
                      },
                      {
                          name: 'Trade Finance 360 Dashboard',
                          icon: 'mdi-view-dashboard',
                          path: '/supervision/tf360/dashboard',
                          show: function() {return this.isSolutionTf360;},
                          type: 'single',
                      },
                      {
                          name: 'Trade Finance 799 Queries',
                          icon: 'mdi-table-of-contents',
                          path: '/supervision/tf799/tables',
                          show: function() {return this.isSolutionTf799;},
                          type: 'single',
                      },
                      {
                          name: 'Trade Finance 799 Dashboard',
                          icon: 'mdi-view-dashboard',
                          path: '/supervision/tf799/dashboard',
                          show: function() {return this.isSolutionTf799;},
                          type: 'single',
                      },
                      {
                          name: 'PKYC Queries',
                          icon: 'mdi-table-of-contents',
                          path: '/supervision/pkyc/tables',
                          show: function() {return this.isSolutionPkyc;},
                          type: 'single',
                      },
                      {
                          name: 'PKYC Dashboard',
                          icon: 'mdi-view-dashboard',
                          path: '/supervision/pkyc/dashboard',
                          show: function() {return this.isSolutionPkyc && this.userRole !== rolesObject.ANALYST;},
                          type: 'single',
                      },
                      {
                          name: 'ACH Files',
                          icon: 'mdi-table-of-contents',
                          path: '/supervision/ach/tables',
                          show: function() {return this.isSolutionAch;},                        
                          type: 'single',
                      },
                      {
                          name: 'ACH Dashboard',
                          icon: 'mdi-view-dashboard',
                          path: '/supervision/ach/dashboard',
                          show: function() {return this.isSolutionAch;},    
                          type: 'single',
                      },
                      {
                          name: 'Manual Search Queries',
                          icon: 'mdi-table-of-contents',
                          path: '/supervision/aml/tables',
                          show: function() {return this.isSolutionAml;},    
                          type: 'single',
                      },
                  ],
                },
                {
                  name: 'Data sources',
                  icon: 'mdi-database',
                  type: 'multi',
                  show: function() {return this.isUserSystemAdmin || this.isUserAdmin || this.isUserBsa;},
                  children: [
                      {
                          name: 'Custom lists',
                          icon: 'mdi-table-of-contents',
                          path: '/admin/custom-sources',
                          type: 'single',
                          show: function() {return !this.isUserBsa;},
                      },      
                      {
                          name: 'Custom Countries list',
                          icon: 'mdi-table-of-contents',
                          path: '/admin/custom-countries',
                          type: 'single',
                          show: function() {return !this.isUserBsa;},
                      },            
                      {
                          name: 'Official Lists',
                          icon: 'mdi-table-of-contents',
                          path: '/admin/summary-reports',
                          type: 'single',
                          show: function() { return this.isUserSystemAdmin || this.isUserBsa;},
                      },
                      {
                          name: 'Official Lists', 
                          icon: 'mdi-table-of-contents',
                          path: '/admin/available-sources',
                          type: 'single',
                          show: function() {return this.isUserAdmin; },
                      },
                      {
                          name: 'SDN Updates', 
                          icon: 'mdi-table-of-contents',
                          path: '/admin/sdn-updates',
                          type: 'single',
                          show: true,
                      },
                      {
                          name: 'Accounts for 314',
                          icon: 'mdi-table-of-contents',
                          path: '/admin/custom-accounts',
                          type: 'single',
                          show: function() {return !this.isUserBsa;},
                      },
                      {
                          name: 'Sources in-use',
                          icon: 'mdi-table-of-contents',
                          path: '/admin/data-sources',
                          type: 'single',
                          show: function() { return this.isUserSystemAdmin && !this.isUserBsa;},
                      },
                  ],
                },
                {
                    name: 'Persistence',
                    icon: 'mdi-file-compare',
                    type: 'multi',
                    show: function() { return this.isUserSystemAdmin || this.isUserAdmin || this.isUserBsa;},
                    children: [
                        {
                            name: 'Wire',
                            icon: 'mdi-table-of-contents',
                            path: '/admin/persistence',
                            type: 'single',
                            show: true,
                        },
                        {
                            name: 'pKYC',
                            icon: 'mdi-table-of-contents',
                            path: '/admin/pkyc-persistence',
                            type: 'single',
                            show: function() { return this.isUserSystemAdmin || this.isSolutionPkyc;},
                        },       
                        {
                          name: 'ACH',
                          icon: 'mdi-table-of-contents',
                          path: '/admin/ach-persistence',
                          type: 'single',
                          show: true,
                        },
                    ],
                },
                {
                    name: 'Reports',
                    icon: 'mdi-list-box-outline',
                    type: 'multi',
                    show: function() {return this.isUserSystemAdmin;},
                    children: [
                        {
                            name: 'Daily report',
                            icon: 'mdi-table-of-contents',
                            path: '/reports/daily',
                            type: 'single',
                            show: true,
                        },
                        {
                            name: 'Solutions Reports',
                            icon: 'mdi-table-of-contents',
                            path: '/reports',
                            type: 'single',
                            show: true,
                        },
                    ],
                },
                {
                    name: 'Settings',
                    icon: 'mdi-account-plus-outline',
                    type: 'multi',
                    show: function() {return this.isUserSystemAdmin || this.isUserAdmin;},
                    children: [
                        {
                            name: 'User Management',
                            icon: 'mdi-account-multiple-plus',
                            path: '/admin/user-management',
                            show: true,
                            type: 'single',
                        },
                        {
                            name: 'User Audit',
                            icon: 'mdi-account-multiple-plus',
                            path: '/admin/user-audit',
                            show: true,
                            type: 'single',
                        },
                        {
                            name: 'Tenants',
                            icon: 'mdi-bank',
                            path: '/admin/tenant-management',
                            show: function() {return this.isUserSystemAdmin;},
                            type: 'single',
                        },
                        {
                            name: 'Tenants Audit',
                            icon: 'mdi-bank',
                            path: '/admin/tenant-audit',
                            show: function() {return this.isUserSystemAdmin;},
                            type: 'single',
                        },                      
                        {
                            name: 'System configurations',
                            icon: 'mdi-cog',
                            path: '/admin/system-configuration',
                            show: function() {return ActionRoles.adminRoles.includes(this.userRole);},
                            type: 'single',
                        },
                    ],
                }, 
                {
                    name: 'Admin Control',
                    icon: 'mdi-security',
                    path: '/admin/system-control',
                    show: function() {return this.isUserSystemAdmin && !this.$store.getters.readOnly;},
                    type: 'single',
                },
                {
                    name: 'Team Structure',
                    icon: 'mdi-account-plus-outline',
                    path: '/supervision/users',
                    show: function() {return this.isUserChief;},
                    type: 'single',
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            userRole: 'userRole',
            userId: 'userId',
        }),
        isAuthenticated(){
            return this.$store.getters.isAuthenticated;
        },
        useBatch() {
          return JSON.parse(localStorage.getItem(USE_BATCH));
        },
        useCompareNames() {
          return JSON.parse(localStorage.getItem(COMPARE_NAMES_KEY));
        },
        isUserSystemAdmin() {
            return this.userRole === rolesObject.SYS_ADMIN;
        },
        isUserAdmin() {
            return this.userRole === rolesObject.ADMIN;
        },
        isUserChief() {
          return this.userRole === rolesObject.CHIEF_SUPERVISOR;
        },        
        isUserBsa() {
          return this.userRole === rolesObject.BSA;
        },
        isSolutionWire() {
          return this.userSolutions?.includes(SOLUTIONS.wire);
        },
        isSolutionAml() {
          return this.userSolutions?.includes(SOLUTIONS.aml);
        },
        isSolutionAch() {
          return this.userSolutions?.includes(SOLUTIONS.ach);
        },
        isSolutionPkyc() {
          return this.userSolutions?.includes(SOLUTIONS.pkyc);
        },
        isSolutionTf360() {
          return this.userSolutions?.includes(SOLUTIONS.tf360);
        },
        isSolutionTf799() {
          return this.userSolutions?.includes(SOLUTIONS.tf799);
        },

        filteredItems() {
          return this.items
            .filter(item => typeof item.show === 'function' ? item.show.call(this) : item.show)
            .map(item => {
              if (item.type === 'multi' && item.children) {
                return {
                  ...item,
                  children: item.children.filter(child => typeof child.show === 'function' ? child.show.call(this) : child.show),
                };
              }
              return item;
            });
        },
    },
    watch: {
        isDrawerOpen(value) {
            this.drawerOpen = value;
        },
        drawerOpen(value) {
            this.$emit('updateToggle', value); 
        },
    },

    methods: {
        ...mapActions({ logout: AUTH_LOGOUT }),
        routeTo(page) {
            const currentPath = this.$router.currentRoute.value.name;
            if (currentPath !== page) {
                this.$router.push({ path: page });
            }
        },
        async doLogout() {
          await this.logout({token: '', isForce: false, messageForLogout: 'Manual logout'});
          this.$router.go({ name: 'login' });
        },
    },
};
</script>

<style>
    .v-list-item--active {
        background: #161a4f !important;
        color: #fff !important;
    }

    .v-list-group--open > div:first-child {
      background-color: #dedede !important;
      color: black !important;
    }
</style>
