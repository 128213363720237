import BaseAPIClient from '@/services/baseClient';
import apiClient from './apiClient';
import {USER_ROLE_KEY, USER_TOKEN_KEY} from '@/helpers/localStorageLabels';

export class BatchClient extends BaseAPIClient {
  createInstance() {
    super.createInstance();

    this.instance.defaults.baseURL = process.env.NODE_ENV === 'production'
      ? '/api/v1/'
      : process.env.VUE_APP_CACHE_API_URI;

    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          const userToken = sessionStorage.getItem(USER_TOKEN_KEY);
          const userRole = localStorage.getItem(USER_ROLE_KEY);
          if (userRole === 'ANALYST') {
            //TEMP
            const localStorageData = {};
            for (let i = 0; i < localStorage.length; i++) {
              const key = localStorage.key(i);
              const value = localStorage.getItem(key);
              localStorageData[key] = value;
            }
            (async function () {
              await apiClient.cleanLocalStorage('cacheServiceClient.js', JSON.stringify(localStorageData));
            })();
            //TEMP
            apiClient.logout(userToken);
            console.log('CLEAR LOCAL STORAGE BECAUSE: resp.status = 401 (cacheService.js)');
            localStorage.clear();
            location.reload();
          }
        }
        return Promise.reject(error);
      },
    );
  }

  async resetWhiteList() {
    return this.instance.put('/admin/settings/whitelist_reload');
  }

  async resetUsers() {
    return this.instance.put('/admin/settings/users_reload');
  }

  // resetTenants
  async resetTenants() {
    return this.instance.put('/admin/settings/tenants_reload');
  }

  async getUsers(role, tenantId) {
    return this.instance.get(`/users?userRole=${role}&tenantId=${tenantId}`);
  }

  async getTeamsFromCache(tenantId, positionNames) {
    let urlForRequest = '/get-teams?';
    if (tenantId !== undefined) {
      urlForRequest += `tenantId=${tenantId}&`;
    }
    if (positionNames) {
      urlForRequest += `positionNames=${positionNames}&`;
    }
    return this.instance.get(urlForRequest);
  }

  async getAchProgress(tenantId, fileId) {
    if (fileId) {
      return this.instance.get(`/getAchProgress?fileId=${fileId}&tenantId=${tenantId}`); 
    } else {
      return this.instance.get(`/getAchProgress?tenantId=${tenantId}`); 
    }
  }
}

export default new BatchClient();
